<template>
    <div class="text-center">
        <v-bottom-sheet
                v-model="sheet"
                persistent
        >
            <template v-slot:activator="{ on, attrs }">
            </template>
            <v-sheet>
                <v-toolbar elevation="0" flat color="white" class="d-flex justify-end pr-5">
<!--                    <v-btn small tile outlined color="secondary" @click="sheet = !sheet" dark>-->
<!--                        <v-icon small>mdi-export</v-icon>-->
<!--                        &lt;!&ndash;{{ $t('general.buttons.export') }}&ndash;&gt;-->
<!--                        EXPORT-->
<!--                    </v-btn>-->
                    <v-btn class="ml-1" small tile outlined color="gray" @click="sheet = !sheet" dark>
                        <v-icon small>mdi-close</v-icon>
                        {{ $t('general.buttons.close') }}
                    </v-btn>
                </v-toolbar>
                <div class="py-3 pt-0">
                    <v-container ref="content">
                        <div class="row">
                            <div class="col-5">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                        <tr>
                                            <td class="font-weight-black">{{ $t('reports.titles.contract') }}#</td>
                                            <td>{{itemInSheet.contract}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-black">{{ $t('reports.titles.bags') }}</td>
                                            <td>{{itemInSheet.totalVolumen}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-black">{{ $t('reports.titles.benefited') }} (%)</td>
                                            <td>{{itemInSheet.totalBenefitPercent}}%</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-black">{{ $t('reports.titles.bags') }} {{ $t('reports.titles.beneficiaries') }}</td>
                                            <td>{{itemInSheet.totalBenefitVolumen}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-black">{{ $t('reports.titles.bankruptcyChart') }} (%)</td>
                                            <td>{{itemInSheet.totalDiffQuebraPercent}}%</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-black">≠ {{ $t('reports.titles.bags') }}</td>
                                            <td>{{itemInSheet.totalDiffQuebraVolum}}</td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                            <div class="col-7">
                                <v-simple-table dense class="text-center d-flex flex-wrap flex-column align-content-center">
                                    <template v-slot:default>
                                        <thead>
                                            <th></th>
                                            <th class="text-center font-weight-black" style="color: #03AC8C" colspan="2" v-for="classification in itemInSheet.groups">{{translate(classification.languageKey)}}</th>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td></td>
                                            <template v-for="classification in itemInSheet.groups">
                                                <td class="font-weight-black">%</td>
                                                <td class="font-weight-black" >{{ $t('reports.titles.bags') }}</td>
                                            </template>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-black">{{ $t('reports.titles.contract') }}</td>
                                            <template v-for="classification in itemInSheet.groups">
                                                <td class="font-weight-black">{{classification.contract.percent}}%</td>
                                                <td class="font-weight-black">{{classification.contract.quantity}}</td>
                                            </template>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-black">{{ $t('reports.titles.benefited') }}</td>
                                            <template v-for="classification in itemInSheet.groups">
                                                <td class="font-weight-black">{{classification.benefit.percent}}%</td>
                                                <td class="font-weight-black">{{classification.benefit.quantity}}</td>
                                            </template>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-black">{{ $t('reports.titles.difference') }}</td>
                                            <template v-for="classification in itemInSheet.groups">
                                                <td class="font-weight-black">{{classification.quebra.percent}}%</td>
                                                <td class="font-weight-black">{{classification.quebra.quantity}}</td>
                                            </template>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                        </div>
                    </v-container>
                </div>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>

<script>
    import LanguajeService from "../../../services/LanguajeService";

    export default {
        name: "SheetContracts",
        computed: {
            itemInSheet: {
                get() {
                    return this.$store.getters['reports/itemInSheet'];
                },
                set(val) {
                    this.$store.dispatch('reports/setItemInSheet', val);
                }
            },
            sheet: {
                get() {
                    return this.$store.getters['reports/sheetContract'];
                },
                set(val) {
                    this.$store.dispatch('reports/setSheetContract', val);
                }
            },
        },
        methods: {
            // Translate function (consume Language Service)
            translate(key) {
                return LanguajeService.getKey3(key)
            },
            getColor (x) {
                if ((parseFloat(x)) === 0) {
                    return 'gray';
                } else {
                    if ((parseFloat(x)) < 0) {
                        return 'success';
                    } else {
                        return 'error';
                    }
                }
            },
        }
    }
</script>

<style scoped>

</style>