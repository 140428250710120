<template>
  <v-container fluid ref="content">
    <v-list-item three-line class="mb-0">
      <v-list-item-content>
        <p class="display-1 text--primary">
          {{ $t("reports.titles.contract") }}
        </p>
      </v-list-item-content>
    </v-list-item>
    <v-card class="pb-4" elevation="0" height="280px">
      <v-card-text class="px-0 py-0">
        <v-data-table
          :no-results-text="$t('home.titles.searchNotFound')"
          :items="getContractsByCompany"
          :headers="headers"
          class="agTable"
          :loading="loading"
          :options.sync="options"
          :footer-props="{
            ...rowsPerPageItemsOptions,
            itemsPerPageText: $t('general.table.itemsPerPageText'),
            pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
          }"
          :server-items-length="totalContracts"
          :no-data-text="$t('general.ui.not_data_found')"
          :loading-text="$t('general.notification.loadData')"
        >
          <template v-slot:top>
            <v-row class="d-flex justify-space-between align-center">
              <v-col lg="4" md="4" sm="12" xl="4">
                <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replace" v-on:keyup.ctrl.86="replace"
                  dense
                  filled
                  rounded
                  color="secondary"
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('general.titles.filter')"
                  single-line
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col lg="4" md="4" sm="8" xl="4">
                <v-toolbar flat color="white">
                  <v-spacer></v-spacer>
                  <v-badge
                    bordered
                    color="secondary"
                    overlap
                    :content="cantFilters"
                    left
                    :value="cantFilters"
                  >
                    <v-btn
                      small
                      tile
                      :elevation="0"
                      color="#d8d8d896"
                      style="border-radius: 1.5em;"
                      @click="openFilters"
                      class="mb-2 mr-1"
                    >
                      <v-icon small color="#6D6D6D" left
                        >mdi-filter-variant</v-icon
                      >
                      <span style="color:#6D6D6D">{{
                        $t("general.buttons.filter")
                      }}</span>
                    </v-btn>
                  </v-badge>
                </v-toolbar>
              </v-col>
            </v-row>
            <!--FILTERS TOOLBAR *********************************************************************-->
            <v-toolbar
              class="mb-3"
              flat
              style="background-color:#f4f2f2; border-radius: 0.3em"
              v-if="filtersChips && filtersChips.length > 0"
            >
              <v-row style="display: contents">
                <v-col cols="12">
                  <v-chip-group active-class="neuter--text">
                    <v-chip
                      small
                      color="#FFFFFF"
                      close
                      v-for="chip in filtersChips"
                      :key="chip.value"
                      @click:close="cleanFilter(chip.value)"
                    >
                      <v-avatar left>
                        <v-icon small>mdi-filter-variant</v-icon>
                      </v-avatar>
                      {{ chip.name }}
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip
              x-small
              :color="getCellClass(item.totalDiffQuebraPercent)"
              dark
            >
            </v-chip>
          </template>
          <template v-slot:item.totalDiffQuebraPercent="{ item }">
            <v-btn text :color="getCellClass(item.totalDiffQuebraPercent)">{{
              item.totalDiffQuebraPercent + "%"
            }}</v-btn>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon small color="neuter" class="ml-2" @click="openSheets(item)"
              >mdi-eye-outline</v-icon
            >
          </template>
        </v-data-table>
        <sheet-contracts />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapActions, mapGetters } from "vuex";
import SheetContracts from "./components/SheetContracts";
import moment from "moment";
import _ from "lodash";

const FILTER_VALUES = [
  "template_names",
  "company_id",
  "process_ids",
  "products_ids",
  "seasons_ids",
  "person_in_charge_ids",
  "start_record_date_time",
  "end_record_date_time",
  "structure",
  "pageableDTO",
  "record_ids",
  "storage_devices_ids",
  "min_punctuation",
  "max_punctuation",
  "surveyStateType",
  "storage_device_types_ids",
  "sdi_origin_ids",
  "expiration_date",
  "document_date",
  "document_authority_ids",
  "document_type_ids",
  "document_description_ids",
  "sdi_destination_ids",
  "lots",
  "sellers_date",
  "volumeB_range",
  "volumeN_range",
  "quebra_range",
  "withExternalVisibility"
];

export default {
  name: "ReContracts",
  components: {
    SheetContracts,
  },
  data: () => ({
    profile: null,
    search: "",
    // Pagination
    rowsPerPageItemsOptions: {
      itemsPerPageOptions: [10, 20, 50, 100, 200],
    },
    options: {},
  }),
  watch: {
    options: {
      handler() {
        this.optionsTable = this.options;
        this.getContractsPaginatedByCompany();
      },
      deep: true,
    },
  },
  created() {
    this.profile = JSON.parse(localStorage.getItem("profile"));
  },
  mounted() {
    this.$store.commit("ui/FILTERS_ORIGINAL_STATE");
    let filtersContracts = JSON.parse(localStorage.getItem("filtersContracts"));

    if (filtersContracts) {
      this.contractsRanges.volumeN =
        JSON.stringify(filtersContracts.contractsRanges.volumeN) ===
        JSON.stringify([0, 100])
          ? [this.volumesN["volumeN_min"], this.volumesN["volumeN_max"]]
          : filtersContracts.contractsRanges.volumeN;
      this.contractsRanges.volumeB =
        JSON.stringify(filtersContracts.contractsRanges.volumeB) ===
        JSON.stringify([0, 100])
          ? [this.volumesB["volumeB_min"], this.volumesB["volumeB_max"]]
          : filtersContracts.contractsRanges.volumeB;
      this.contractsRanges.quebra =
        JSON.stringify(filtersContracts.contractsRanges.quebra) ===
        JSON.stringify([0, 100])
          ? [this.quebra["quebra_min"], this.quebra["quebra_max"]]
          : filtersContracts.contractsRanges.quebra;
      this.date.filtersContractsDate = filtersContracts.filtersContractsDate
        ? filtersContracts.filtersContractsDate
        : [];
      this.date["contracts_dates"] = filtersContracts.contracts_dates
        ? filtersContracts.contracts_dates
        : "";
      this.filtersObjects = filtersContracts.filtersObjects
        ? filtersContracts.filtersObjects
        : [];
    } else {
      this.contractsRanges.volumeN = [
        this.volumesN["volumeN_min"],
        this.volumesN["volumeN_max"],
      ];
      this.contractsRanges.volumeB = [
        this.volumesB["volumeB_min"],
        this.volumesB["volumeB_max"],
      ];
      this.contractsRanges.quebra = [
        this.quebra["quebra_min"],
        this.quebra["quebra_max"],
      ];
    }

    if (
      this.date.filtersContractsDate &&
      this.date.filtersContractsDate.length > 0
    )
      this.processRangeDates("contracts_dates", "filtersContractsDate");

    if (this.filtersObjects) {
      this.closeFilterDialog();
    }

    this.$root.$on("setFiltersContracts", () => {
      this.closeFilterDialog();
    });

    this.$root.$on("getResultsPaginatedByCompany", () => {
      this.getContractsPaginatedByCompany();
    });
  },
  methods: {
    ...mapActions({
      getContracts: "reports/fetchDataFilteredForVoiter",
      getContract: "reports/getContract",
    }),
    processRangeDates(stringTag, field) {
      let [year, month, day] = "-";
      let [year2, month2, day2] = "-";
      if (this.date[field] && this.date[field].length > 1) {
        if (this.date[field][0]) {
          [year, month, day] = this.date[field][0].split("-");
        }
        if (this.date[field][1]) {
          [year2, month2, day2] = this.date[field][1].split("-");
        }
        this.filtersObjects[
          field
        ] = `${day}/${month}/${year} ~ ${day2}/${month2}/${year2}`;
      } else {
        this.filtersObjects[stringTag] = [];
      }
    },
    openFilters() {
      this.type = "contracts";
      this.drawer = true;
    },
    closeFilterDialog() {
      this.applyFilters();
      this.filteredChips();
      this.getCantFilters();
      this.show = false;
    },
    getContractsPaginatedByCompany() {
      const direction =
        this.options.sortDesc.length === 0 || this.options.sortDesc[0]
          ? "desc"
          : "asc";
      const sort =
        this.options.sortBy.length === 0
          ? "id"
          : _.filter(this.headers, { value: this.options.sortBy[0] })[0].val;
      this.filters.pageableDTO = {
        page: this.options.page - 1,
        size: this.options.itemsPerPage,
        sortBy: sort,
        direction: direction,
      };

      this.filters.company_id = this.profile.company_id;

      const keys = [
        "company_id",
        "pageableDTO",
        "contracts_dates",
        "contracts_names",
        "quebra_range",
        "sellers_ids",
        "volumeB_range",
        "volumeN_range",
      ];
      const filters = _.cloneDeep(_.pick(this.filters, keys));

      this.getContracts([this.$toast, filters]);
    },
    applyFilters() {
      let filtersContracts = JSON.parse(
        localStorage.getItem("filtersContracts")
      );
      if (filtersContracts) {
        this.filters.sellers_ids =
          filtersContracts.filtersObjects.sellers_ids &&
          filtersContracts.filtersObjects.sellers_ids.length > 0
            ? filtersContracts.filtersObjects.sellers_ids
            : null;
        this.filters.contracts_names =
          filtersContracts.filtersObjects.contracts_names &&
          filtersContracts.filtersObjects.contracts_names.length > 0
            ? filtersContracts.filtersObjects.contracts_names
            : null;
        this.filters.contracts_dates =
          filtersContracts.filtersContractsDate &&
          filtersContracts.filtersContractsDate.length > 0
            ? [
                new Date(
                  filtersContracts.filtersContractsDate[0] + "T00:00:00.000Z"
                )
                  .toISOString()
                  .slice(0, 19),
                new Date(
                  filtersContracts.filtersContractsDate[1] + "T23:59:59.000Z"
                )
                  .toISOString()
                  .slice(0, 19),
              ]
            : [];
        this.filters.volumeB_range =
          filtersContracts.contractsRanges.volumeB &&
          filtersContracts.contractsRanges.volumeB.length > 0 &&
          JSON.stringify(filtersContracts.contractsRanges.volumeB) !==
            JSON.stringify([0, 100])
            ? filtersContracts.contractsRanges.volumeB
            : [];
        this.filters.volumeN_range =
          filtersContracts.contractsRanges.volumeN &&
          filtersContracts.contractsRanges.volumeN.length > 0 &&
          JSON.stringify(filtersContracts.contractsRanges.volumeN) !==
            JSON.stringify([0, 100])
            ? filtersContracts.contractsRanges.volumeN
            : [];
        this.filters.quebra_range =
          filtersContracts.contractsRanges.quebra &&
          filtersContracts.contractsRanges.quebra.length > 0 &&
          JSON.stringify(filtersContracts.contractsRanges.quebra) !==
            JSON.stringify([0, 100])
            ? filtersContracts.contractsRanges.quebra
            : [];
      }
      this.getContractsPaginatedByCompany();
    },
    cleanFilter(tag) {
      let filtersContracts = JSON.parse(
        localStorage.getItem("filtersContracts")
      );
      if (this.filters[tag]) {
        if (this.filtersObjects[tag]) {
          this.filtersObjects[tag] = [];
          filtersContracts.filtersObjects[tag] = [];
        }
        if (tag === "volumeB_range") {
          this.contractsRanges.volumeB = [
            this.volumesB["volumeB_min"],
            this.volumesB["volumeB_max"],
          ];
          filtersContracts.contractsRanges["volumeB"] = [
            this.volumesB["volumeB_min"],
            this.volumesB["volumeB_max"],
          ];
          this.filters["volumeB_range"] = [];
        } else if (tag === "volumeN_range") {
          this.contractsRanges.volumeN = [
            this.volumesN["volumeN_min"],
            this.volumesN["volumeN_max"],
          ];
          filtersContracts.contractsRanges["volumeN"] = [
            this.volumesN["volumeN_min"],
            this.volumesN["volumeN_max"],
          ];
          this.filters["volumeN_range"] = [];
        } else if (tag === "quebra_range") {
          this.contractsRanges.quebra = [
            this.quebra["quebra_min"],
            this.quebra["quebra_max"],
          ];
          filtersContracts.contractsRanges["quebra"] = [
            this.quebra["quebra_min"],
            this.quebra["quebra_max"],
          ];
          this.filters["quebra_range"] = [];
        } else if (tag === "contracts_dates") {
          this.filters["contracts_dates"] = [];
          this.date.filtersContractsDate = [];
          filtersContracts.contracts_dates = false;
          filtersContracts.filtersContractsDate = [];
          filtersContracts.filtersObjects.contracts_dates = "";
        } else {
          this.filters[tag] = null;
          filtersContracts[tag] = null;
        }
      }
      localStorage.setItem(
        "filtersContracts",
        JSON.stringify(filtersContracts)
      );
      this.applyFilters();
      this.filteredChips();
      this.getCantFilters();
    },
    filteredChips() {
      let chips = [];
      _.forEach(this.filters, (value, key) => {
        if (
          ![...FILTER_VALUES].includes(key) &&
          this.filters[key] !== null &&
          this.filters[key].length > 0
        ) {
          chips.push({
            value: key,
            name: _.filter(this.filterHeader, { val: key })[0].text,
          });
        }

        if (
          key === "volumeN_range" &&
          (parseInt(this.contractsRanges.volumeN[0]) >
            this.volumesN["volumeN_min"] ||
            parseInt(this.contractsRanges.volumeN[1]) <
              this.volumesN["volumeN_max"])
        ) {
          chips.push({
            value: key,
            name: _.filter(this.filterHeader, { val: key })[0].text,
          });
        }
        if (
          key === "volumeB_range" &&
          (parseInt(this.contractsRanges.volumeB[0]) >
            this.volumesB["volumeB_min"] ||
            parseInt(this.contractsRanges.volumeB[1]) <
              this.volumesB["volumeB_max"])
        ) {
          chips.push({
            value: key,
            name: _.filter(this.filterHeader, { val: key })[0].text,
          });
        }
        if (
          key === "quebra_range" &&
          (parseInt(this.contractsRanges.quebra[0]) >
            this.quebra["quebra_min"] ||
            parseInt(this.contractsRanges.quebra[1]) <
              this.quebra["quebra_max"])
        ) {
          chips.push({
            value: key,
            name: _.filter(this.filterHeader, { val: key })[0].text,
          });
        }
      });

      this.filtersChips = chips;
    },
    getCantFilters() {
      let cant = 0;
      _.forEach(this.filters, (value, key) => {
        if (
          ![...FILTER_VALUES, "contracts_dates"].includes(key) &&
          value !== null
        ) {
          cant += value.length;
        }
        cant += key === "contracts_dates" && value && value.length > 0 ? 1 : 0;
        cant +=
          key === "volumeN_range" &&
          (parseInt(value[0]) > this.volumesN["volumeN_min"] ||
            parseInt(value[1]) < this.volumesN["volumeN_max"])
            ? 1
            : 0;
        cant +=
          key === "volumeB_range" &&
          (parseInt(value[0]) > this.volumesB["volumeB_min"] ||
            parseInt(value[1]) < this.volumesB["volumeB_max"])
            ? 1
            : 0;
        cant +=
          key === "quebra_range" &&
          (parseInt(value[0]) > this.quebra["quebra_min"] ||
            parseInt(value[1]) < this.quebra["quebra_max"])
            ? 1
            : 0;
      });
      this.cantFilters = cant;
    },
    async openSheets(item) {
      await this.getContract([item.contract, this.$toast]);
      this.sheet = true;
    },
    getCellClass(value) {
      if (parseFloat(value) === 0) {
        return "gray";
      } else {
        if (parseFloat(value) < 0) {
          return "success";
        } else {
          return "error";
        }
      }
    },
    getContractsGetters(search) {
      let items = this.contracts;
      if (search) {
        items = this.searchOnAll(items);
      }
      return items;
    },
    searchOnAll(items) {
      return items.filter((item) => {
        return Object.values(item)
          .join(",")
          .toUpperCase()
          .includes(this.search.toUpperCase());
      });
    },
  },
  computed: {
    ...mapGetters({
      contracts: "reports/contracts",
      totalContracts: "reports/totalContracts",
      loading: "reports/loading",
      quebra: "reports/quebra",
      volumesN: "reports/volumesN",
      volumesB: "reports/volumesB",
      //----ui-----------------------------------------
      filterHeader: "ui/GET_HEADERS_CONTRACTS",
    }),
    filters: {
      get() {
        return this.$store.getters["ui/GET_FILTERS"];
      },
      set(val) {
        this.$store.dispatch("ui/CONTRACTS_RANGES", val);
      },
    },
    type: {
      get() {
        return this.$store.getters["ui/GET_FILTER_TYPE"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_FILTER_TYPE", val);
      },
    },
    drawer: {
      get() {
        return this.$store.getters["ui/GET_DRAWER"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_DRAWER", val);
      },
    },
    filtersObjects: {
      get() {
        return this.$store.getters["ui/GET_OBJECTS_TO_FILTERS"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_OBJECTS_TO_FILTERS", val);
      },
    },
    date: {
      get() {
        return this.$store.getters["ui/GET_DATE_FILTERS"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_DATE_FILTERS", val);
      },
    },
    optionsTable: {
      get() {
        return this.$store.getters["ui/GET_OPTIONS_TABLE"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_OPTIONS_TABLE", val);
      },
    },
    sheet: {
      get() {
        return this.$store.getters["reports/sheetContract"];
      },
      set(val) {
        this.$store.dispatch("reports/setSheetContract", val);
      },
    },
    filtersChips: {
      get() {
        return this.$store.getters["ui/GET_FILTERS_CHIPS"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_FILTERS_CHIPS", val);
      },
    },
    cantFilters: {
      get() {
        return this.$store.getters["ui/GET_COUNT_FILTERS"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_COUNT_FILTERS", val);
      },
    },
    contractsRanges: {
      get() {
        return this.$store.getters["ui/GET_CONTRACTS_RANGES"];
      },
      set(val) {
        this.$store.commit("ui/CONTRACTS_RANGES", val);
      },
    },
    contractsData: {
      get() {
        return this.$store.getters["ui/GET_CONTRACTS_DATA"];
      },
      set(val) {
        this.$store.commit("ui/CONTRACTS_DATA", val);
      },
    },
    headers: () => [
      {
        text: "",
        value: "status",
        width: 150,
        sortable: false,
      },
      {
        text: "No. " + i18n.t("reports.titles.contract"),
        value: "contract",
        type: "text",
        sortable: false,
      },
      {
        text: i18n.t("reports.titles.seller"),
        value: "seller.control_label",
        type: "text",
        sortable: false,
      },
      {
        text: i18n.t("reports.titles.volumeChart"),
        value: "totalVolumen",
        type: "text",
        sortable: false,
      },
      {
        text: i18n.t("reports.titles.volumeBenefit"),
        value: "totalBenefitVolumen",
        type: "text",
        sortable: false,
      },
      {
        text: i18n.t("reports.titles.bankruptcyChart"),
        value: "totalDiffQuebraPercent",
        type: "text",
        sortable: false,
      },
      {
        // la traduccion para actions ya esta disponible
        text: i18n.t("general.fields.action"),
        value: "action",
        width: 150,
        sortable: false,
        type: "actions",
      },
    ],
    getContractsByCompany() {
      return this.getContractsGetters(this.search);
    },
    replace(event){
      event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
      this.search =  event.currentTarget.value.toString().replaceAll('"','')
    }
  },
  destroyed() {
    this.$root.$off("setFiltersContracts");
    this.$root.$off("getResultsPaginatedByCompany");
  },
};
</script>

<style scoped>
:deep(.v-chip-group .v-slide-group__content) {
  display: flex !important;
  justify-content: flex-start !important;
}
:deep(.v-toolbar__content) {
  padding: 0 !important;
}
</style>
